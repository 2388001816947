module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"business-elements","short_name":"be","start_url":"/","background_color":"#ED1C24","theme_color":"#ED1C24","display":"minimal-ui","icon":"src/images/be-favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"1f7b28822d49d4b802c2a526ae8584d8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NG736BH"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

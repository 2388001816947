// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nas-pribeh-js": () => import("./../src/pages/nas-pribeh.js" /* webpackChunkName: "component---src-pages-nas-pribeh-js" */),
  "component---src-pages-sluzby-js": () => import("./../src/pages/sluzby.js" /* webpackChunkName: "component---src-pages-sluzby-js" */)
}


import React from 'react';
import PropTypes from 'prop-types';
import GlobalContextProvider from './src/context/GlobalContextProvider';

export const wrapRootElement = ({ element }) => {
    return <GlobalContextProvider>{element}</GlobalContextProvider>
};

wrapRootElement.propTypes = {
    element: PropTypes.node
};

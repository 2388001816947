import React, {createContext, useReducer} from 'react';
import PropTypes from 'prop-types';

export const GlobalStateContext = createContext();
export const GlobalDispatchContext = createContext();

const initialState = {
    contactModalOpened: false,
};

function reducer(state, action) {
    switch (action.type) {
        case "OPEN_MODAL": {
            return {
                ...state,
                contactModalOpened: true,
            }
        }
        case "CLOSE_MODAL": {
            return {
                ...state,
                contactModalOpened: false,
            }
        }
        default:
            throw new Error("Bad Action Type")
    }
}

const GlobalContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    return (
        <GlobalStateContext.Provider value={state}>
            <GlobalDispatchContext.Provider value={dispatch}>
                {children}
            </GlobalDispatchContext.Provider>
        </GlobalStateContext.Provider>
    )
};

GlobalContextProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default GlobalContextProvider
